import { useState, useEffect } from "react"

export default function Hilight(props) {
    const layout = props.layout;

    return (
        <div className={layout["hilight-blocks"]}>
            <h3>ไฮไลท์ในขณะนี้</h3>
            <div className={layout["hilight-lists"]}>
                <ul>
                    <li className={layout["brown"]}>
                        <img src="images/products/CB_20230713_HP_UGC1.jpg" alt={""} />
                        <div className={layout["hilight-info"]}>
                            <h4>เลือกสินค้าออนไลน์ง่ายขึ้นเยอะ</h4>
                            <p>ช้อปสะดวก ลดการสัมผัส ช้อปออนไลน์ ผ่านบริการ Click & Collect ไม่มีค่าใช้จ่าย</p>
                            <div className={layout["hilight-btn"]}>
                                <button className={layout["next-btn"]}><i className="fa-solid fa-arrow-right"></i></button>
                            </div>
                        </div>
                    </li>
                    <li className={layout["navi"]}>
                        <img src="images/products/CB_20230713_HP_UGC2.jpg" alt={""} />
                        <div className={layout["hilight-info"]}>
                            <h4>ขั้นตอนการวางแผนและซื้อสินค้า</h4>
                            <p>นัดหมายออนไลน์เพื่อรับคำแนะนำ โดยไม่มีค่าบริการเพิ่มเติม หรือเลือกดูวิธิการเลือกสินค้าเพิ่มเติมได้เลย </p>
                            <div className={layout["hilight-btn"]}>
                                <button className={layout["next-btn"]}><i className="fa-solid fa-arrow-right"></i></button>
                            </div>
                        </div>
                    </li>
                    <li className={layout["grey"]}>
                        <img src="images/products/CB_20230713_HP_UGC4.jpg" alt={""} />
                        <div className={layout["hilight-info"]}>
                            <h4>จัดส่งเริ่มต้น 99 บาท</h4>
                            <p>บริการจัดส่งแบบพัสดุและจัดส่งแบบรถบรรทุก เริ่มต้นที่ 99 บาท</p>
                            <div className={layout["hilight-btn"]+" "+layout["white"]}>
                                <button className={layout["next-btn"]}><i className="fa-solid fa-arrow-right"></i></button>
                            </div>
                        </div>
                    </li>
                    <li className={layout["black"]}>
                        <img src="images/products/CB_20230713_HP_UGC7.jpg" alt={""} />
                        <div className={layout["hilight-info"]}>
                            <h4>Click & Collect</h4>
                            <p>ช้อปสะดวก ลดการสัมผัส ช้อปออนไลน์ ผ่านบริการ Click & Collect ไม่มีค่าใช้จ่าย</p>
                            <div className={layout["hilight-btn"]}>
                                <button className={layout["next-btn"]}><i className="fa-solid fa-arrow-right"></i></button>
                            </div>
                        </div>
                    </li>
                    <li className={layout["grey"]}>
                        <img src="images/products/CB_20230713_HP_UGC4.jpg" alt={""} />
                        <div className={layout["hilight-info"]}>
                            <h4>จัดส่งเริ่มต้น 99 บาท</h4>
                            <p>บริการจัดส่งแบบพัสดุและจัดส่งแบบรถบรรทุก เริ่มต้นที่ 99 บาท</p>
                            <div className={layout["hilight-btn"]+" "+layout["white"]}>
                                <button className={layout["next-btn"]}><i className="fa-solid fa-arrow-right"></i></button>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    )
}
