import React from 'react'

export default function Footer({layout}) {
  return (
    <footer className={layout["footer"]}>
        <div className={layout["footer-container"]}>
            <ul className={layout["footer-nav"]}>
                <li className={layout["hr"]}>
                    <h3>Our Company</h3>
                    <ul>
                        <li><a href="/">About Us</a></li>
                        <li><a href="/">Carreers</a></li>
                        <li><a href="/">Responsible Design</a></li>
                        <li><a href="/">Store Location</a></li>
                    </ul>
                </li>
                <li className={layout["hr"]}>
                    <h3>Resources</h3>
                    <ul>
                        <li><a href="/">Customer Service</a></li>
                        <li><a href="/">Account</a></li>
                        <li><a href="/">Return Policy</a></li>
                        <li><a href="/">Shipping Information</a></li>
                        <li><a href="/">Catalogs</a></li>
                        <li><a href="/">Product Recalls</a></li>
                        <li><a href="/">Cookie Settings</a></li>
                    </ul>
                </li>
                <li className={layout["hr"]}>
                    <h3>Shopping App</h3>
                    <p>Try our View in Your Room <br/>feature, manage registries and<br/> save payment info.</p>
                </li>
                <li className={layout["hr"]}>
                    <h3>Social Media</h3>
                    <ul className={layout["footer-social"]}>
                        <li><a href="/"><i className="fa-brands fa-facebook-f"></i></a></li>
                        <li><a href="/"><i className="fa-brands fa-instagram"></i></a></li>
                        <li><a href="/"><i className="fa-brands fa-pinterest-p"></i></a></li>
                        <li><a href="/"><i className="fa-brands fa-tiktok"></i></a></li>
                        <li><a href="/"><i className="fa-brands fa-youtube"></i></a></li>
                    </ul>
                    <ul>
                        <li><a href="/">Show us your look with:</a></li>
                        <li><a href="/">#CeramicSTC</a></li>
                        <li><a href="/">#เซรามิคเอส.ที.ซี.</a></li>
                    </ul>
                </li>
            </ul>
            <div className={layout["copyright"]}>
                <img src="images/logo.png" alt={"บริษัทเซรามิค เอส.ที.ซี. จำกัด"} />
                <p>บริษัทเซรามิค เอส.ที.ซี. จำกัด | CERAMIC STC CO.,LTD<br/>
                    98 หมู่ 10 ตำบลปงแสนทอง อำเภอเมืองลำปาง จังหวัดลำปาง 52100<br/>
                    ติดต่อ : ฝ่ายขาย  Tel : 054-367-490  Fax: 054-367-491 Mobile: 095-141-7878, 095-141-7171, 081-883-9538<br/>
                    Opening Hours : Mon-Sat : 10:00 – 20:00 Email: info@ceramicstc.co.th | Line: @ceramicstc  | Facebook : facebook.com/ceramicstc
                </p>
            </div>
        </div>
    </footer>
  )
}
