import { useState, useEffect, useContext } from 'react'
import Products from '../components/Homepage/Products'
import Hilight from '../components/Homepage/Hilight'
import Collection from '../components/Homepage/Collection'
import classes from '../assets/css/module/layout.module.css';

export default function Home() {

  useEffect(()=>{
  },[]);

  return (
    <>
      <div className={classes["homepage"]}>
        <Products layout={classes}/>
        <Hilight layout={classes}/>
        <Collection layout={classes}/>
      </div>
    </>
  )
}
