import { useState, useEffect, useContext } from 'react'
import NewCollection from '../components/Product/NewCollection'
import ProductCategory from '../components/Product/Category'
import ProductMenu from '../components/Product/Menu'
import ProductNav from '../components/Product/Nav'
import ProductLists from '../components/Product/Lists'
import classes from '../assets/css/module/product.module.css';
import { useParams } from 'react-router'
import API from '../utils/api';

export default function Category() {  
    const params = useParams();
    const category_id = params.category_id;
    const [keyword, setKeyword] = useState('');
    const [category, setCategory] = useState(null);
    const [categories, setCategories] = useState(null);

    useEffect(() => {
        fetchInit();
        if(category_id){
            fetchData();
        }
    }, []);

    async function fetchInit(){
        await API.get(`/categories`)
        .then(function (res) {
            // console.log(res.data.results);
            if(res.data.status===true){
                let result = res.data.result;
                setCategories(result)
                console.log(result);
            }
        })
        .catch(function (err) {
            console.log(err);
        });
    }

    async function fetchData(){
        await API.get(`/categories/`+category_id+"/products")
        .then(function (res) {
            // console.log(res.data.result);
            if(res.data.status===true){
                let result = res.data.result;
                setCategory(result)
                // console.log(result);
            }
        })
        .catch(function (err) {
            console.log(err);
        });
    }


  return (
    <>
        <ProductNav layout={classes} product={null} category={category} keyword={keyword}/>
        <div className={classes["product"]}>
            <ProductMenu layout={classes} categories={categories}/>
            {(category_id)?
            <ProductLists layout={classes} category_id={category_id} category={category} keyword={keyword}/>
            :
            <ProductCategory layout={classes} categories={categories}/>
            }
        </div>
        <div className={classes["homepage"]}>
            <NewCollection layout={classes}/>
        </div>
    </>
  )
}
