import React, { useState, useEffect, useRef } from 'react';
import { Outlet,useLocation } from "react-router-dom";
import layout from '../assets/css/module/layout.module.css';
import ThemeContext from '../context/ThemeContext';
import Footer from './Footer'
import {page_url} from '../utils/lang';

export default function Layout() {
    const [language, setLang] = useState("th");
    const [page, setPage] = useState("About Us");
    const [init, setInit] = useState({src:undefined});

    const handleMouseOver = (item_id) => {
        const altElements = document.getElementsByClassName(layout["image-catalog-tag"]);
        Array.prototype.forEach.call(altElements, function(element) {
            if(element.classList.contains(layout["show"])){
                element.classList.remove(layout["show"])
            }
        });

        const altElement = document.getElementById("info-"+item_id);
        if(!altElement.classList.contains(layout["show"])){
            altElement.classList.add(layout["show"])
        }
    };
    
      const handleMouseOut = () => {
        const altElements = document.getElementsByClassName(layout["image-catalog-tag"]);
        Array.prototype.forEach.call(altElements, function(element) {
            if(element.classList.contains(layout["show"])){
                element.classList.remove(layout["show"])
            }
        });
      };


    return (
    <ThemeContext.Provider
      value={{ language, page, init }}
    >   
        <nav className={layout["sticky-container"]} onMouseOver={e=>handleMouseOut()}>
            <div className={layout["logo"]}> <img src={page_url+"images/logo.png"} alt={""} /></div>
            {/* <div className={layout["filter"]}><i className={"fa-solid fa-sliders"}/>Filter</div> */}
            <div className={layout["menu"]}><i className={"fa-solid fa-bars"}/>Menu</div>
        </nav>
        <div className={layout["body"]}>
                <Outlet />
                <Footer layout={layout} />
        </div>
    </ThemeContext.Provider>
  )
}
