import React, { useState, useEffect } from 'react';
import API from '../utils/api';
export default function Playground({classes}) {    
    const [products, setProducts] = useState(null);
    const [message, setMessage] = useState(null);

    useEffect(() => {
        fetchData();
      }, []);
  
  
    async function fetchData(){
        await API.get(`/products/111111`)
        .then(function (res) {
          // console.log(res.data.results);
            if(res.data.status===true){
                let result = res.data.result;
                console.log(result);
            }
        })
        .catch(function (err) {
            console.log(err);
        });
    }

    return (
        <>
        {(products)?products:"Can't get data"}
        </>
      );  
}