import React from "react"
import { Routes, Route } from "react-router-dom"
import Layout from "./components/Layout"
import HomeLayout from "./components/HomeLayout"
import Home from "./views/Home";
import Category from "./views/Category";
import Product from "./views/Product";
import Playground from "./views/Playground";

function App() {
  return (
    <Routes>
        <Route element={<Layout />} >
            <Route path="/playground" element={<Playground />} />
            <Route path="/category" element={<Category />} />
            <Route path="/category/:category_id" element={<Category />} />
            <Route path="/product/:product_id" element={<Product />} />
        </Route>
        <Route path="/" element={<HomeLayout />} >
            <Route path="*" element={<Home />} />
            <Route path="/" element={<Home />} />
        </Route>
    </Routes>
  );
}

export default App;
