import { useState, useEffect } from "react"
import { lang, img_url } from '../../utils/lang'

export default function Collection({layout}) {

    const handleMouseOver = (item_id) => {
        const altElements = document.getElementsByClassName(layout["image-catalog-tag"]);
        Array.prototype.forEach.call(altElements, function(element) {
            if(element.classList.contains(layout["show"])){
                element.classList.remove(layout["show"])
            }
        });

        const altElement = document.getElementById("info-"+item_id);
        if(!altElement.classList.contains(layout["show"])){
            altElement.classList.add(layout["show"])
        }
    };
    
    const handleMouseOut = () => {
        const altElements = document.getElementsByClassName(layout["image-catalog-tag"]);
        Array.prototype.forEach.call(altElements, function(element) {
            if(element.classList.contains(layout["show"])){
                element.classList.remove(layout["show"])
            }
        });
      };

    return (
        <>
        <div className={layout["collection-blocks"]}>
            <div className={layout["image-catalog"]}>
                <img src="images/products/CB_SP23_SSC_1_308_Ver_002.png" alt={""} />
                <div className={layout["image-catalog-info"]}>
                    <ul>
                        <li key="item-5" style={{top:"35%", left:"50%"}} >
                            <div id="dot-5" className={layout["image-catalog-dot"]} onMouseOver={e=>handleMouseOver(5)}></div>
                            <div id="info-5" className={layout["image-catalog-tag"]+' '+layout["right"]+' '} >
                                <p>จานรุ่น..........</p>
                                <button className={layout["right-btn"]}>Info..</button>
                            </div>
                        </li>
                        <li key="item-6" style={{top:"54%", left:"24%"}}>
                            <div id="dot-6" className={layout["image-catalog-dot"]} onMouseOver={e=>handleMouseOver(6)}></div>
                            <div id="info-6" className={layout["image-catalog-tag"]+' '+layout["bottom-right"]+' '}>
                                <p>แก้วน้ำ..........</p>
                                <button className={layout["right-btn"]}>Info..</button>
                            </div>
                        </li>
                        <li key="item-7" style={{top:"54%", left:"68%"}}>
                            <div id="dot-7" className={layout["image-catalog-dot"]} onMouseOver={e=>handleMouseOver(7)}></div>
                            <div id="info-7" className={layout["image-catalog-tag"]+' '+layout["bottom-left"]+' '}>
                                <p>แก้วน้ำรุ่น..........</p>
                                <button className={layout["right-btn"]}>Info..</button>
                            </div>
                        </li>
                        <li key="item-8" style={{top:"80%", left:"50%"}}>
                            <div id="dot-8" className={layout["image-catalog-dot"]} onMouseOver={e=>handleMouseOver(8)}></div>
                            <div id="info-8" className={layout["image-catalog-tag"]+' '+layout["bottom-left"]+' '}>
                                <p>แก้วน้ำรุ่น..........</p>
                                <button className={layout["right-btn"]}>Info..</button>
                            </div>
                        </li>
                    </ul>
                </div>
          </div>
          <div className={layout["sub-title"]} onMouseOver={e=>handleMouseOut()}>
                <ul className={layout["image-catalog-lists"]}>
                    <li>
                        <img src="images/products/le-creuset-cream-cereal-bowls-set-of-4.jpg" alt={""} />
                        <div className={layout["image-catalog-lists-info"]}>
                            <h4>Le Creuset ® Chambray Blue Bowls, Set of 4</h4>
                            <p>ชามใส่อาหาร 4 6.1" dia. x 2.4"H</p>
                        </div>
                        <div className={layout["image-catalog-lists-btn"]}>
                            <button className={layout["catalog-btn"]}><i className="fa-solid fa-basket-shopping"></i></button>
                            <button className={layout["favorite-btn"]}><i className="fa-regular fa-heart"></i></button>
                        </div>
                    </li>
                    <li>
                        <img src="images/products/le-creuset-chambray-blue-cereal-bowls-set-of-4.jpg" alt={""} />
                        <div className={layout["image-catalog-lists-info"]}>
                            <h4>Le Creuset ® Chambray Blue Bowls, Set of 4</h4>
                            <p>ชามใส่อาหาร 4 6.1" dia. x 2.4"H</p>
                        </div>
                        <div className={layout["image-catalog-lists-btn"]}>
                            <button className={layout["catalog-btn"]}><i className="fa-solid fa-basket-shopping"></i></button>
                            <button className={layout["favorite-btn"]}><i className="fa-regular fa-heart"></i></button>
                        </div>
                    </li>
                    <li>
                        <img src="images/products/le-creuset-white-mugs-set-of-4.jpg" alt={""} />
                        <div className={layout["image-catalog-lists-info"]}>
                            <h4>Le Creuset ® Chambray Blue Bowls, Set of 4</h4>
                            <p>ชามใส่อาหาร 4 6.1" dia. x 2.4"H</p>
                        </div>
                        <div className={layout["image-catalog-lists-btn"]}>
                            <button className={layout["catalog-btn"]}><i className="fa-solid fa-basket-shopping"></i></button>
                            <button className={layout["favorite-btn"]}><i className="fa-regular fa-heart"></i></button>
                        </div>
                    </li>
                    <li>
                        <img src="images/products/le-creuset-ink-blue-mugs-set-of-4.jpg" alt={""} />
                        <div className={layout["image-catalog-lists-info"]}>
                            <h4>Le Creuset ® Chambray Blue Bowls, Set of 4</h4>
                            <p>ชามใส่อาหาร 4 6.1" dia. x 2.4"H</p>
                        </div>
                        <div className={layout["image-catalog-lists-btn"]}>
                            <button className={layout["catalog-btn"]}><i className="fa-solid fa-basket-shopping"></i></button>
                            <button className={layout["favorite-btn"]}><i className="fa-regular fa-heart"></i></button>
                        </div>
                    </li>
                </ul>
          </div>
        </div>
        </>
    )
}
