import { useState, useEffect } from "react"

export default function Products(props) {
    const layout = props.layout;

    return (
        <div className={layout["products-blocks"]}>
            <div className={layout["products-blocks-1"]}>
                <div className={layout["left"]}>
                    <img src="images/products/CB_SP23_MB_01_524_hero_02.jpg" alt={""} />
                </div>
                <div className={layout["right"]}>
                    <div className={layout["item-inner"]}>
                        <img src="images/products/CB_SP23_MB_09_524_Vert_v5_03.jpg" alt={""} />
                        <img src="images/products/CB_SP23_MB_09_524_Vert_v2_01.jpg" alt={""} />
                    </div>
                    <div className={layout["item-3"]}>
                        
                        <img src="images/products/cae-sal-cream-yellow-stoneware-serving-bowl-by-molly-baz.jpg" alt={""} />
                    </div>
                </div>
            </div>
            <div className={layout["products-blocks-2"]}>
                <ul>
                    <li>
                        <div className={layout["collection-info"]}>
                            <div className={layout["detail"]}>
                                <h4>New Collection</h4>
                                <div className={layout["line-separator"]}></div>
                                <p>A fresh collection full of cook curves, bold color and clever details that only a cook like your dream up.</p>
                                <a href="/">learn more <i className="fa-solid fa-arrow-right"></i></a>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div className={layout["products-detail"]}>
                        <div className={layout["product-img"]}>
                            <img src="images/products/the-tini-glass--paprika-red-by-molly-baz.jpg" alt={""} />
                        </div>
                        <p>Product Name</p>
                        </div>
                    </li>
                    <li>
                        <div className={layout["products-detail"]}>
                        <div className={layout["product-img"]}>
                            <img src="images/products/MBTheDinPlateAVSSS23.jpg" alt={""} />
                        </div>
                        <p>Product Name</p>
                        </div>
                    </li>
                    <li>
                        <div className={layout["products-detail"]}>
                        <div className={layout["product-img"]}>
                            <img src="images/products/stoneware-utensil-holder-by-molly-baz.jpg" alt={""} />
                        </div>
                        <p>Product Name</p>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    )
}
