import Cookies from 'universal-cookie';
export const cookies = new Cookies();
// cookies.set('myCat', 'Pacman', { path: '/' });
// console.log(cookies.get('myCat')); 

export const page_url = 'https://ceramicstc.magnexium.com/';
// export const page_url = 'http://localhost:3000/';

// export const img_url = 'http://localhost/ceramicstc/api/images/';
// export const img_url = 'http://localhost:3000/';
export const img_url = 'https://ceramicstc.magnexium.com/';
export const thumbnail_url= 'https://ceramic.magnexium.com/storage/';
export const month_th = [ "มกราคม", "กุมภาพันธ์", "มีนาคม", "เมษายน", "พฤษภาคม", "มิถุนายน", "กรกฎาคม", "สิงหาคม", "กันยายน", "ตุลาคม", "พฤศจิกายน", "ธันวาคม"];
export const month_th_mini = [ "ม.ค.", "ก.พ.", "มี.ค.", "เม.ย.", "พ.ค.", "มิ.ย.", "ก.ค.", "ส.ค.", "ก.ย.", "ต.ค.", "พ.ย.", "ธ.ค."];
export const month_en = ["January","February","March","April","May","June","July","August","September","October","November","December"];
export const month_en_mini = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul","Aug", "Sep", "Oct", "Nov", "Dec"];
export const month_cn = ["一月", "二月", "三月", "四月", "五月", "六月", "七月","八月", "九月", "十月", "十一月", "十二月"];

export function addLeadingZeros(num, totalLength) {
    return String(num).padStart(totalLength, '0');
}

export function chk_img(img){
    if(img && img !== undefined){
        return img_url + img;
    }else{
        return img_url + 'slide.jpg';
    }
}

export function lang(name_th, name_en, name_ch){
    let language = localStorage.getItem("lang");
    if(language===null){
        language = "th";
        localStorage.setItem("lang","th");
    } 
    if((name_en===''||name_en===null)&&language==='en'){
        name_en = name_th;
        localStorage.setItem("warning",true);
    }
    if((name_ch===''||name_ch===null)&&language==='cn'){
        name_ch = name_en;
        if(name_en===''||name_en===null){
            name_ch = name_th;
        }
        localStorage.setItem("warning",true);
    }

    switch(language) {
        case 'th':
            return name_th;
        case 'cn':
            return name_ch; 
        default:
            return name_en;
    }
}

export function getDate(event_date){
    let day_name = event_date.substr(8,2);
    let year_name = Number(event_date.substr(0,4));
    let event_month = Number(event_date.substr(5,2))-1;
    let month_name = lang(month_th[event_month], month_en[event_month], month_en[event_month]);

    let language = localStorage.getItem("lang");
    if(language==='en'){
        return day_name+' '+month_name+' '+year_name;
    }else{
        year_name = year_name+543;
        return day_name+' '+month_name+' '+year_name;
    }
}
export function thDateFormat(input_date){
    var date = new Date(input_date);

    var year = date.getFullYear()+543;
    var month = month_th[date.getMonth()];
    var day = date.getDate();

    return day + " " + month + " " + year + "  "
}
export function enDateFormat(input_date){
    var date = new Date(input_date);

    var year = date.getFullYear();
    var month = month_en[date.getMonth()];
    var day = date.getDate();

    return day + " " + month + " " + year + "  "
}

export function cnDateFormat(input_date){
    var date = new Date(input_date);

    var y_num = ["〇","一","二","三","四","五","六","七","八","九","十","十一"]
    var d_num = ["零","一","二","三","四","五","六","七","八","九","十",
                "十一","十二","十三","十四","十五","十六","十七","十八","十九","二十",
                "二十一","二十二","二十三","二十四","二十五","二十六","二十七","二十八","二十九","三十",
                "三十一"]
    let y_tmp = String(date.getFullYear());
    let y = [...y_tmp]
    var year = y_num[y[0]]+y_num[y[1]]+y_num[y[2]]+y_num[y[3]];
    var month = month_cn[date.getMonth()];
    var day = d_num[date.getDate()];

    return year + "年 " + month + " " + day + "日  "
}

export function getTextSize(){
    let body_text = "text-small";
    let type = cookies.get('text-size');
    if(type==='large'){
        body_text="text-large";
    }else if(type==='normal'){
        body_text="text-normal";
    }
    return body_text;
}
export function gotoURL(url){
    if(url){
        window.location = page_url+url.substring(1);
    }else{
        window.location = page_url;
    }
}
export function gotoLink(url){
    if(url){
        window.open(url);
    }else{
        window.open(page_url);
    }
}

export function openLink(e){
    e.preventDefault();
    let url = e.currentTarget.getAttribute('data-href');
    window.open(url);
}

export function check_brightness(color) {
    if(!color){
        console.log(color);
        return true;
    }
    const hex = color.replace('#', '');
    const c_r = parseInt(hex.substr(0, 2), 16);
    const c_g = parseInt(hex.substr(2, 2), 16);
    const c_b = parseInt(hex.substr(4, 2), 16);
    const brightness = ((c_r * 299) + (c_g * 587) + (c_b * 114)) / 1000;
    return brightness > 155;
}