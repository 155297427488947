import React, { useState, useEffect } from 'react';
import {page_url} from '../../utils/lang';

export default function Nav({layout,category,product}) {
    return (
    <div className={layout["nav-container"]}>
        <div className={layout["wrapper"]}>
            <ul className={layout.nav}>
                <li><a href={page_url}>Home</a></li>
                <li><a href={page_url+"category"}>All Category</a></li>
            {(category)?
                <li><a href={page_url+"category/"+category.id}>{category.name}</a></li>
            :''}
            {(product)?
                <li><a href="/">{product.category_id}</a></li>
            :''}
            {(product)?
                <li><a href="/">{product.name}</a></li>
            :''}
            </ul>
        </div>
    </div>
    );

}