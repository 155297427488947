import { useState, useEffect } from "react"
import {page_url} from '../../utils/lang';


export default function NewCollection(props) {
    const layout = props.layout;

    return (
        <div className={layout["products-blocks"]}>
            <div className={layout["products-blocks-2"]}>
                <ul>
                    <li>
                        <div className={layout["collection-info"]}>
                            <div className={layout["detail"]}>
                                <h4>New Collection</h4>
                                <div className={layout["line-separator"]}></div>
                                <p>A fresh collection full of cook curves, bold color and clever details that only a cook like your dream up.</p>
                                <a href="/">learn more <i className="fa-solid fa-arrow-right"></i></a>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div className={layout["products-detail"]}>
                        <div className={layout["product-img"]}>
                            <img src={page_url+"images/products/the-tini-glass--paprika-red-by-molly-baz.jpg"} alt={""} />
                        </div>
                        <p>Product Name</p>
                        </div>
                    </li>
                    <li>
                        <div className={layout["products-detail"]}>
                        <div className={layout["product-img"]}>
                            <img src={page_url+"images/products/MBTheDinPlateAVSSS23.jpg"} alt={""} />
                        </div>
                        <p>Product Name</p>
                        </div>
                    </li>
                    <li>
                        <div className={layout["products-detail"]}>
                        <div className={layout["product-img"]}>
                            <img src={page_url+"images/products/stoneware-utensil-holder-by-molly-baz.jpg"} alt={""} />
                        </div>
                        <p>Product Name</p>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    )
}
