import { useState, useEffect, useContext } from 'react'
import ProductMenu from '../components/Product/Menu'
import ProductNav from '../components/Product/Nav'
import ProductDetail from '../components/Product/Detail'
import NewCollection from '../components/Product/NewCollection'
import classes from '../assets/css/module/product.module.css';
import { useParams } from 'react-router'
import API from '../utils/api';

export default function Product() {
  const params = useParams();
  const product_id = params.product_id;
  const [keyword, setKeyword] = useState('');
  const [product, setProduct] = useState(null);
  const [categories, setCategories] = useState(null);

  useEffect(()=>{
    fetchInit();
    if(product_id){
        fetchData();
    }
  },[]);

  async function fetchInit(){
    await API.get(`/categories`)
    .then(function (res) {
        // console.log(res.data.results);
        if(res.data.status===true){
            let result = res.data.result;
            setCategories(result)
            console.log(result);
        }
    })
    .catch(function (err) {
        console.log(err);
    });
}

  async function fetchData(){
      await API.get(`/products/`+product_id)
      .then(function (res) {
          // console.log(res.data.result);
          if(res.data.status===true){
              let result = res.data.result;
              setProduct(result)
              console.log(result);
          }
      })
      .catch(function (err) {
          console.log(err);
      });
  }

  return (
    <>
        <ProductNav layout={classes} product={product}/>
        <div className={classes["product"]}>
            {(product)?
            <ProductDetail layout={classes} product_id={product_id} product={product} keyword={keyword}/>
            :''}
        </div>
        <div className={classes["homepage"]}>
            <NewCollection layout={classes}/>
        </div>
    </>
  )
}
