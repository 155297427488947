import { useState, useEffect } from "react"
import { thumbnail_url, gotoURL } from '../../utils/lang';


export default function Lists(props) {
    const layout = props.layout;
    const lists = props.category?.products;

    return (
        <div className={layout["product-lists"]}>
            {(lists)?
                lists.map((product,index) => (
                    <div className={layout["img-wrapper"]} onClick={e=>gotoURL("/product/"+product.id)} key={'ci-'+index}>
                        <img src={thumbnail_url+product.thumbnail_path.replace('/original','/large')} alt={product.name}></img>
                        <span>{product.name}</span>
                    </div>
                ))
            :""}
        </div>
    );
}