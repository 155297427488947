import { useState, useEffect } from "react"
import { thumbnail_url } from '../../utils/lang';

export default function Products(props) {
    const layout = props.layout;
    const product = props.product;

    return (
        <div className={layout["products-detail"]}>
            <div className={layout["product-img"]}>
                <div className={layout["img-wrapper"]}>
                    <img src={thumbnail_url+product.thumbnail_path.replace('/original','/large')} alt={product.name}></img>
                </div>
                <div className={layout["img-lists"]}>
                {(product.images)?
                    product.images.map((item,index) => (
                        <div key={'pi-'+index}>
                            <img src={thumbnail_url+item.path.replace('/original','/large')} alt={product.name}></img>
                        </div>
                    ))
                :""}
                </div>
            </div>
            <div className={layout["product-info"]}>
                <h1>{product.name}</h1>
                <p>Type: {product.type}</p>
                <p>Width: {product.width}</p>
                <p>Height: {product.height}</p>
                <p>Weight: {product.weight}</p>
                <p>Capacity: {product.capacity}</p>
                <p>Case Pack: {product.case_pack}</p>
                <button>Add to wishlist</button>
            </div>
            <div className={layout["product-desc"]}>
                <h3>Detail</h3>
                <p dangerouslySetInnerHTML={{__html: product.description}}></p>
            </div>
        </div>
    )
}
