import React, { useState, useEffect } from 'react';
import API from '../../utils/api';
import {page_url} from '../../utils/lang';

export default function ProductMenu({layout,categories}) {
    const [products, setProducts] = useState(null);

    return (
        <aside className={layout["aside"]}>
            <div className={layout["aside-container"]}>
                <ul className={layout["aside-nav"]}>
                    {(categories)?
                    <li className={layout["hr"]}>
                        <h3>Category</h3>
                        <ul>
                            {categories.map((option,index) => (
                                <li key={'c-'+index}><a href={page_url+"category/"+option.id}>{option.name}</a></li>
                            ))}
                        </ul>
                    </li>
                    :''}
                    <li className={layout["hr"]}>
                        <h3>Type</h3>
                        <ul>
                            <li><a href={page_url}>Type 1</a></li>
                            <li><a href={page_url}>Type 2</a></li>
                            <li><a href={page_url}>Type 3</a></li>
                        </ul>
                    </li>
                    <li className={layout["hr"]}>
                        <h3>Shopping App</h3>
                        <p>Try our View in Your Room <br/>feature, manage registries and<br/> save payment info.</p>
                    </li>
                    <li className={layout["hr"]}>
                        <h3>Social Media</h3>
                        <ul className={layout["aside-social"]}>
                            <li><a href="/"><i className="fa-brands fa-facebook-f"></i></a></li>
                            <li><a href="/"><i className="fa-brands fa-instagram"></i></a></li>
                            <li><a href="/"><i className="fa-brands fa-pinterest-p"></i></a></li>
                            <li><a href="/"><i className="fa-brands fa-tiktok"></i></a></li>
                            <li><a href="/"><i className="fa-brands fa-youtube"></i></a></li>
                        </ul>
                        <ul>
                            <li>Show us your look with:</li>
                            <li><a href="/">#CeramicSTC</a></li>
                            <li><a href="/">#เซรามิคเอส.ที.ซี.</a></li>
                        </ul>
                    </li>
                </ul>
            </div>
        </aside>
    )
}
