import React, { useState, useEffect, useRef } from 'react';
import { Outlet,useLocation } from "react-router-dom";
import layout from '../assets/css/module/layout.module.css';
import ThemeContext from '../context/ThemeContext';
import Footer from './Footer'

export default function HomeLayout() {
    const [language, setLang] = useState("th");
    const [page, setPage] = useState("About Us");
    const [init, setInit] = useState({src:undefined});

    const handleMouseOver = (item_id) => {
        const altElements = document.getElementsByClassName(layout["image-catalog-tag"]);
        Array.prototype.forEach.call(altElements, function(element) {
            if(element.classList.contains(layout["show"])){
                element.classList.remove(layout["show"])
            }
        });

        const altElement = document.getElementById("info-"+item_id);
        if(!altElement.classList.contains(layout["show"])){
            altElement.classList.add(layout["show"])
        }
    };
    
      const handleMouseOut = () => {
        const altElements = document.getElementsByClassName(layout["image-catalog-tag"]);
        Array.prototype.forEach.call(altElements, function(element) {
            if(element.classList.contains(layout["show"])){
                element.classList.remove(layout["show"])
            }
        });
      };


    return (
    <ThemeContext.Provider
      value={{ language, page, init }}
    >   <header className={layout["header"]}>
            <div className={layout["image-catalog"]}>
                <img src="images/main-slide.png" alt={""} />
                <div className={layout["image-catalog-info"]}>
                    <ul>
                        <li key="item-1" style={{top:"35%", left:"50%"}} >
                            <div id="dot-1" className={layout["image-catalog-dot"]} onMouseOver={e=>handleMouseOver(1)}></div>
                            <div id="info-1" className={layout["image-catalog-tag"]+' '+layout["right"]+' '} >
                                <p>จานรุ่น..........</p>
                                <button className={layout["right-btn"]}>Info..</button>
                            </div>
                        </li>
                        <li key="item-2" style={{top:"54%", left:"24%"}}>
                            <div id="dot-2" className={layout["image-catalog-dot"]} onMouseOver={e=>handleMouseOver(2)}></div>
                            <div id="info-2" className={layout["image-catalog-tag"]+' '+layout["bottom-right"]+' '}>
                                <p>แก้วน้ำ..........</p>
                                <button className={layout["right-btn"]}>Info..</button>
                            </div>
                        </li>
                        <li key="item-3" style={{top:"54%", left:"72%"}}>
                            <div id="dot-3" className={layout["image-catalog-dot"]} onMouseOver={e=>handleMouseOver(3)}></div>
                            <div id="info-3" className={layout["image-catalog-tag"]+' '+layout["bottom-left"]+' '}>
                                <p>แก้วน้ำรุ่น..........</p>
                                <button className={layout["right-btn"]}>Info..</button>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </header>
        <nav className={layout["sticky-container"]} onMouseOver={e=>handleMouseOut()}>
            <div className={layout["logo"]}> <img src="images/logo.png" alt={""} /></div>
            {/* <div className={layout["filter"]}><i className={"fa-solid fa-sliders"}/>Filter</div> */}
            <div className={layout["menu"]}><i className={"fa-solid fa-bars"}/>Menu</div>
        </nav>
        <div className={layout["body"]}>
                <Outlet />
                <Footer layout={layout} />
        </div>
    </ThemeContext.Provider>
  )
}
